// This is a file that can be overridden by the application in order to override styles
// Notice that this file is included at the very end of the stylesheets packs to have
// more priority
//
// To override CSS variables or Foundation settings use _decidim-settings.scss
//
// By default this is empty.

@import "email/email-custom";
@import "modules/footer";

.recepisse {
  text-align: justify;
  padding: 1rem;
  font-size: large;
}

.date, .signature{
  text-align: right;
}

.end, .date, .signature {
  font-size: medium;
}

.recepisse-head{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.recepisse-head h1 {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
}

.images_signature {
  display: flex;
  flex-direction: column;
  align-items: end;
}
